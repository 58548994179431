import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import classNames from 'classnames';
import ImageIcon from 'mdi-react/ImageIcon';
import { formValueSelector } from 'redux-form';
import store from '../../containers/App/store';
import UrlValidator from '../classes/UrlValidator';

const PopupImage = ({
  title,
  color,
  colored,
  header,
  reduxFormName,
  fieldName,
  fieldSubName,
  fieldIndex,
}) => {
  const [data, setData] = useState({
    modal: false,
    url: '',
  });

  const getIcon = () => {
    let icon;

    switch (color) {
      case 'primary':
        icon = <span className="lnr lnr-pushpin modal__title-icon" />;
        break;
      case 'success':
        icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
        break;
      case 'warning':
        icon = <span className="lnr lnr-flag modal__title-icon" />;
        break;
      case 'danger':
        icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
        break;
      default:
        break;
    }

    return icon;
  };

  const modalClass = () => classNames({
    'modal-dialog--colored': colored,
    'modal-dialog--header': header,
  });

  const toggle = () => {
    let url = '';

    if (!data.modal) {
      const selector = formValueSelector(reduxFormName);
      const field = selector(store.getState(), fieldName);

      if (UrlValidator.isValidUrl(field[fieldIndex][fieldSubName])) {
        url = field[fieldIndex][fieldSubName];
      }
    }

    setData({ ...data, modal: !data.modal, url });
  };

  return (
    <Fragment>
      <div className="form__form-group-icon">
        <ImageIcon color={color} onClick={toggle} />
      </div>
      <Modal
        isOpen={data.modal}
        toggle={toggle}
        className={`modal-dialog--${color} ${modalClass()}`}
      >
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggle} />
          {header ? '' : getIcon()}
          <h4 className="bold-text  modal__title">{title}</h4>
        </div>
        {data.modal ? (
          <div className="modal__body pr-4 pl-4">
            {data.url ? (
              <img src={data.url} width="100%" border="0" alt="" />
            ) : (
              <p className="center-text red-text">Неверный URL адрес</p>
            )}
          </div>
        ) : null}
        <ButtonToolbar className="modal__footer">
          <Button onClick={toggle}>Закрыть</Button>
        </ButtonToolbar>
      </Modal>
    </Fragment>
  );
};

PopupImage.defaultProps = {
  title: '',
  colored: false,
  header: false,
};

PopupImage.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  reduxFormName: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  fieldSubName: PropTypes.string.isRequired,
  fieldIndex: PropTypes.number.isRequired,
};

export default PopupImage;
