import moment from 'moment';
import { startSubmit, stopSubmit } from 'redux-form';
import ws from '../../ws';
import store from '../../containers/App/store';
import getToken from './getToken';
import PaginationPageConverter from '../../shared/classes/PaginationPageConverter';

const MATERIALS_PROGRESS = 'progress';
const MATERIALS_SUCCESS = 'success';
const MATERIALS_FAILED = 'failed';

const MATERIALS_GET_LIST_PROGRESS = 'MATERIALS_GET_LIST_PROGRESS';
const MATERIALS_GET_LIST_SUCCESS = 'MATERIALS_GET_LIST_SUCCESS';
const MATERIALS_GET_LIST_FAILED = 'MATERIALS_GET_LIST_FAILED';
const MATERIALS_FORM_SUBMIT_PROGRESS = 'MATERIALS_FORM_SUBMIT_PROGRESS';
const MATERIALS_FORM_SUBMIT_SUCCESS = 'MATERIALS_FORM_SUBMIT_SUCCESS';
const MATERIALS_FORM_SUBMIT_FAILED = 'MATERIALS_FORM_SUBMIT_FAILED';
const MATERIALS_GET_MATERIAL_PROGRESS = 'MATERIALS_GET_MATERIAL_PROGRESS';
const MATERIALS_GET_MATERIAL_SUCCESS = 'MATERIALS_GET_MATERIAL_SUCCESS';
const MATERIALS_GET_MATERIAL_FAILED = 'MATERIALS_GET_MATERIAL_FAILED';
const MATERIALS_PREVIEW_SUBMIT_PROGRESS = 'MATERIALS_PREVIEW_SUBMIT_PROGRESS';
const MATERIALS_PREVIEW_SUBMIT_SUCCESS = 'MATERIALS_PREVIEW_SUBMIT_SUCCESS';
const MATERIALS_PREVIEW_SUBMIT_FAILED = 'MATERIALS_PREVIEW_SUBMIT_FAILED';
const MATERIALS_CLEAR_STATE = 'MATERIALS_CLEAR_STATE';

const MATERIALS_CLEAR_FLASH_MESSAGE = 'MATERIALS_CLEAR_FLASH_MESSAGE';

const materialGetListProgress = () => ({
  type: MATERIALS_GET_LIST_PROGRESS,
});

const materialGetListSuccess = payload => ({
  type: MATERIALS_GET_LIST_SUCCESS,
  payload,
});

const materialGetListFailed = () => ({
  type: MATERIALS_GET_LIST_FAILED,
});

const materialStartFormSubmit = () => ({
  type: MATERIALS_FORM_SUBMIT_PROGRESS,
});

const materialFormSubmitSuccess = payload => ({
  type: MATERIALS_FORM_SUBMIT_SUCCESS,
  payload,
});

const materialFormSubmitFailed = () => ({
  type: MATERIALS_FORM_SUBMIT_FAILED,
});

const materialGetMaterialProgress = () => ({
  type: MATERIALS_GET_MATERIAL_PROGRESS,
});

const materialGetMaterialSuccess = payload => ({
  type: MATERIALS_GET_MATERIAL_SUCCESS,
  payload,
});

const materialGetMaterialFailed = () => ({
  type: MATERIALS_GET_MATERIAL_FAILED,
});

const materialSubmitPreviewProgress = () => ({
  type: MATERIALS_PREVIEW_SUBMIT_PROGRESS,
});

const materialSubmitPreviewSuccess = payload => ({
  type: MATERIALS_PREVIEW_SUBMIT_SUCCESS,
  payload,
});

const materialSubmitPreviewFailed = () => ({
  type: MATERIALS_PREVIEW_SUBMIT_FAILED,
});

const materialClearState = () => ({
  type: MATERIALS_CLEAR_STATE,
});

const convertListToTable = data => new PaginationPageConverter(data, doc => ({
  id: doc.id,
  header: doc.header,
  typeName: doc.typeName,
  duration: doc.duration,
  createdAt: moment(doc.createdAt).locale('ru').format('DD MMM YY г., HH:mm'),
})).getConvertedData();

const getCoursesList = params => (dispatch) => {
  const token = getToken();
  dispatch(materialGetListProgress());

  ws.emit('api/academy/tasks/list', { token, payload: params }, (data) => {
    const { status, payload } = data;
    let action;

    if (status === 'ok') {
      action = materialGetListSuccess(convertListToTable(payload.data));
    } else {
      action = materialGetListFailed();
    }

    dispatch(action);
  });
};

const convertSetMaterialToDB = (data) => {
  const img = Object.prototype.hasOwnProperty.call(data, 'img') ? data.img : '';
  const videos = Object.prototype.hasOwnProperty.call(data, 'videos') ? data.videos : [];
  const audio = Object.prototype.hasOwnProperty.call(data, 'audio') ? data.audio : [];
  const customAudio = Object.prototype.hasOwnProperty.call(data, 'customAudio') ? data.customAudio : [];

  return {
    ...data,
    img: typeof img === 'string' ? img : img.value,
    videos: videos.map(video => ({
      source: video.source,
      alternative: typeof video.alternative === 'string' ? video.alternative : '',
      title: typeof video.title === 'string' ? video.title : '',
      isFree: typeof video.isFree === 'boolean' ? video.isFree : false,
      isLong: typeof video.isLong === 'boolean' ? video.isLong : false,
      wistiaId: typeof video.wistiaId === 'string' ? video.wistiaId : '',
      poster: typeof video.poster === 'string' ? video.poster : '',
    })),
    audio: audio.map(item => item.audio),
    customAudio: customAudio.map(item => ({ source: item.source, title: item.title })),
  };
};

const createMaterial = (data, dispatch, props) => {
  const token = getToken();
  const { form, reset } = props;
  const convertedData = convertSetMaterialToDB(data);

  dispatch(startSubmit(form));
  dispatch(materialStartFormSubmit());

  ws.emit('api/academy/tasks/create', {
    // payload: convertedData, token,
    token, payload: { convertedData },
  }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      reset();
      dispatch(stopSubmit(form));
      dispatch(materialFormSubmitSuccess('Новый материал был успешно создан.'));
    } else {
      dispatch(stopSubmit(form, 'error'));
      dispatch(materialFormSubmitFailed());
    }
  });
};

const previewMaterial = (previewId, data) => async dispatch => new Promise(((resolve, reject) => {
  const token = getToken();
  const convertedData = convertSetMaterialToDB(data);
  convertedData.isPreview = true;

  dispatch(materialSubmitPreviewProgress());

  ws.emit('api/academy/tasks/preview', {
    // payload: convertedData, previewId, token,
    token, payload: { convertedData, previewId },
  }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      const { id } = payload;
      dispatch(materialSubmitPreviewSuccess(payload));
      resolve(id);
    } else {
      dispatch(materialSubmitPreviewFailed());
      reject();
    }
  });
}));

const convertDBMaterialToEdit = (payload) => {
  const { task: taskFromDB } = payload;
  const { videos, audio, customAudio } = taskFromDB;

  const task = {
    id: taskFromDB.id,
    typeName: taskFromDB.typeName,
    duration: taskFromDB.duration,
    header: taskFromDB.header,
    view: taskFromDB.view,
    img: taskFromDB.img,
    videos: videos.map(video => ({
      source: video.source,
      alternative: video.alternative,
      title: video.title,
      isFree: video.isFree,
      isLong: video.isLong,
      wistiaId: video.wistiaId,
      poster: video.poster,
    })),
    audio: audio.map(item => ({ audio: item })),
    customAudio: typeof customAudio !== 'undefined'
      ? customAudio.map(item => ({ source: item.source, title: item.title }))
      : [],
  };

  return { task };
};

const getMaterial = taskId => async (dispatch) => {
  const token = getToken();
  dispatch(materialGetMaterialProgress());

  ws.emit('api/academy/tasks/getById', {
    // taskId, token,
    token, payload: { taskId },
  }, (result) => {
    const { status, payload } = result;
    let action;

    if (status === 'ok') {
      action = materialGetMaterialSuccess(convertDBMaterialToEdit(payload));
    } else {
      action = materialGetMaterialFailed();
    }

    dispatch(action);
  });
};

const updateMaterial = (data, dispatch, props) => {
  const token = getToken();
  const material = store.getState().materials.active;
  const { form } = props;
  const convertedData = convertSetMaterialToDB(data);

  dispatch(startSubmit(form));
  dispatch(materialStartFormSubmit());

  ws.emit('api/academy/tasks/updateById', {
    // taskId: material.id, payload: convertedData, token,
    token, payload: { taskId: material.id, convertedData },
  }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(stopSubmit(form));
      dispatch(materialFormSubmitSuccess('Материал был успешно отредактирован.'));
    } else {
      dispatch(stopSubmit(form, 'error'));
      dispatch(materialFormSubmitFailed());
    }
  });
};

const materialClearFlashMessage = () => ({
  type: MATERIALS_CLEAR_FLASH_MESSAGE,
});

export {
  MATERIALS_PROGRESS,
  MATERIALS_SUCCESS,
  MATERIALS_FAILED,
  MATERIALS_GET_LIST_PROGRESS,
  MATERIALS_GET_LIST_SUCCESS,
  MATERIALS_GET_LIST_FAILED,
  MATERIALS_FORM_SUBMIT_PROGRESS,
  MATERIALS_FORM_SUBMIT_SUCCESS,
  MATERIALS_FORM_SUBMIT_FAILED,
  MATERIALS_GET_MATERIAL_PROGRESS,
  MATERIALS_GET_MATERIAL_SUCCESS,
  MATERIALS_GET_MATERIAL_FAILED,
  MATERIALS_PREVIEW_SUBMIT_PROGRESS,
  MATERIALS_PREVIEW_SUBMIT_SUCCESS,
  MATERIALS_PREVIEW_SUBMIT_FAILED,
  MATERIALS_CLEAR_STATE,
  MATERIALS_CLEAR_FLASH_MESSAGE,
  materialGetListProgress,
  materialGetListSuccess,
  materialGetListFailed,
  materialStartFormSubmit,
  materialFormSubmitSuccess,
  materialFormSubmitFailed,
  materialGetMaterialProgress,
  materialGetMaterialSuccess,
  materialGetMaterialFailed,
  materialClearState,
  getCoursesList,
  convertSetMaterialToDB,
  createMaterial,
  previewMaterial,
  getMaterial,
  updateMaterial,
  materialClearFlashMessage,
};
