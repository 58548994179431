import moment from 'moment';
import { startSubmit, stopSubmit } from 'redux-form';
import ws from '../../ws';
import getToken from './getToken';
import PaginationPageConverter from '../../shared/classes/PaginationPageConverter';

const MARAPHON_TASK_PROGRESS = 'progress';
const MARAPHON_TASK_SUCCESS = 'success';
const MARAPHON_TASK_FAILED = 'failed';

const MARAPHON_TASK_GET_LIST_PROGRESS = 'MARAPHON_TASK_GET_LIST_PROGRESS';
const MARAPHON_TASK_GET_LIST_SUCCESS = 'MARAPHON_TASK_GET_LIST_SUCCESS';
const MARAPHON_TASK_GET_LIST_FAILED = 'MARAPHON_TASK_GET_LIST_FAILED';
const MARAPHON_TASK_FORM_SUBMIT_PROGRESS = 'MARAPHON_TASK_FORM_SUBMIT_PROGRESS';
const MARAPHON_TASK_FORM_SUBMIT_SUCCESS = 'MARAPHON_TASK_FORM_SUBMIT_SUCCESS';
const MARAPHON_TASK_FORM_SUBMIT_FAILED = 'MARAPHON_TASK_FORM_SUBMIT_FAILED';
const MARAPHON_TASK_GET_MATERIAL_PROGRESS = 'MARAPHON_TASK_GET_MATERIAL_PROGRESS';
const MARAPHON_TASK_GET_MATERIAL_SUCCESS = 'MARAPHON_TASK_GET_MATERIAL_SUCCESS';
const MARAPHON_TASK_GET_MATERIAL_FAILED = 'MARAPHON_TASK_GET_MATERIAL_FAILED';
const MARAPHON_TASK_CLEAR_STATE = 'MARAPHON_TASK_CLEAR_STATE';

const MARAPHON_TASK_CLEAR_FLASH_MESSAGE = 'MARAPHON_TASK_CLEAR_FLASH_MESSAGE';

const maraphonTaskGetListProgress = () => ({
  type: MARAPHON_TASK_GET_LIST_PROGRESS,
});

const maraphonTaskGetListSuccess = payload => ({
  type: MARAPHON_TASK_GET_LIST_SUCCESS,
  payload,
});

const maraphonTaskGetListFailed = () => ({
  type: MARAPHON_TASK_GET_LIST_FAILED,
});

const maraphonTaskStartFormSubmit = () => ({
  type: MARAPHON_TASK_FORM_SUBMIT_PROGRESS,
});

const maraphonTaskFormSubmitSuccess = payload => ({
  type: MARAPHON_TASK_FORM_SUBMIT_SUCCESS,
  payload,
});

const maraphonTaskFormSubmitFailed = () => ({
  type: MARAPHON_TASK_FORM_SUBMIT_FAILED,
});

const maraphonTaskGetTasklProgress = () => ({
  type: MARAPHON_TASK_GET_MATERIAL_PROGRESS,
});

const maraphonTaskGetTaskSuccess = payload => ({
  type: MARAPHON_TASK_GET_MATERIAL_SUCCESS,
  payload,
});

const maraphonTaskGetTaskFailed = () => ({
  type: MARAPHON_TASK_GET_MATERIAL_FAILED,
});

const maraphonTaskClearState = () => ({
  type: MARAPHON_TASK_CLEAR_STATE,
});

const convertListToTable = data => new PaginationPageConverter(data, doc => ({
  id: doc.id,
  name: doc.name,
  createdAt: moment(doc.createdAt).locale('ru').format('DD MMM YY г., HH:mm'),
})).getConvertedData();

const getMaraphonTasksList = params => (dispatch) => {
  const token = getToken();
  dispatch(maraphonTaskGetListProgress());

  ws.emit('api/academy/maraphonTasks/list', { token, payload: params }, (data) => {
    const { status, payload } = data;
    let action;

    if (status === 'ok') {
      action = maraphonTaskGetListSuccess(convertListToTable(payload.data));
    } else {
      action = maraphonTaskGetListFailed();
    }

    dispatch(action);
  });
};

const convertSetMaraphonTaskToDB = (data) => {
  const {
    name, content, billets, tasksContent, tasks,
  } = data;
  const videos = Object.prototype.hasOwnProperty.call(data, 'videos') ? data.videos : [];
  const audio = Object.prototype.hasOwnProperty.call(data, 'audio') ? data.audio : [];
  const customAudio = Object.prototype.hasOwnProperty.call(data, 'customAudio') ? data.customAudio : [];
  const isShowContent = typeof data.isShowContent === 'undefined' ? true : !data.isShowContent;

  return {
    name,
    content,
    videos: videos.map(video => ({
      source: video.source,
      alternative: typeof video.alternative === 'string' ? video.alternative : '',
      title: typeof video.title === 'string' ? video.title : '',
      isFree: typeof video.isFree === 'boolean' ? video.isFree : false,
      isLong: typeof video.isLong === 'boolean' ? video.isLong : false,
      wistiaId: typeof video.wistiaId === 'string' ? video.wistiaId : '',
      poster: typeof video.poster === 'string' ? video.poster : '',
    })),
    audio: audio.map(item => item.audio),
    customAudio: customAudio.map(item => ({
      source: item.source,
      title: item.title,
      isFree: typeof item.isFree === 'boolean' ? item.isFree : false,
    })),
    billets,
    tasks: {
      content: tasksContent,
      isShowContent,
      task: tasks,
    },
  };
};

const createMaraphonTask = (data, dispatch, props) => {
  const token = getToken();
  const { form, reset } = props;
  const convertedData = convertSetMaraphonTaskToDB(data);

  dispatch(startSubmit(form));
  dispatch(maraphonTaskStartFormSubmit());

  ws.emit('api/academy/maraphonTasks/create', {
    // payload: convertedData, token,
    token, payload: { convertedData },
  }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      reset();
      dispatch(stopSubmit(form));
      dispatch(maraphonTaskFormSubmitSuccess('Новая тема была успешно создана.'));
    } else {
      dispatch(stopSubmit(form, 'error'));
      dispatch(maraphonTaskFormSubmitFailed());
    }
  });
};

const convertDBMaraphonTaskToEdit = (payload) => {
  const { task: taskFromDB } = payload;
  const {
    videos, audio, customAudio, tasks,
  } = taskFromDB;

  const task = {
    id: taskFromDB.id,
    name: taskFromDB.name,
    content: taskFromDB.content,
    billets: taskFromDB.billets,
    videos: videos.map(video => ({
      source: video.source,
      alternative: video.alternative,
      title: video.title,
      isFree: video.isFree,
      isLong: video.isLong,
      wistiaId: video.wistiaId,
      poster: video.poster,
    })),
    audio: audio.map(item => ({ audio: item })),
    customAudio: typeof customAudio !== 'undefined'
      ? customAudio.map(item => ({
        source: item.source,
        title: item.title,
        isFree: typeof item.isFree === 'boolean' ? item.isFree : false,
      }))
      : [],
    tasksContent: tasks.content,
    isShowContent: !tasks.isShowContent,
    tasks: tasks.task,
  };

  return { task };
};

const getMaraphonTask = taskId => async (dispatch) => {
  const token = getToken();
  dispatch(maraphonTaskGetTasklProgress());

  ws.emit('api/academy/maraphonTasks/getById', {
    // taskId, token,
    token, payload: { taskId },
  }, (result) => {
    const { status, payload } = result;
    let action;

    if (status === 'ok') {
      action = maraphonTaskGetTaskSuccess(convertDBMaraphonTaskToEdit(payload));
    } else {
      action = maraphonTaskGetTaskFailed();
    }

    dispatch(action);
  });
};

const updateMaraphonTask = (data, dispatch, props) => {
  const token = getToken();
  const { id } = data;
  const { form } = props;
  const convertedData = convertSetMaraphonTaskToDB(data);

  dispatch(startSubmit(form));
  dispatch(maraphonTaskStartFormSubmit());

  ws.emit('api/academy/maraphonTasks/updateById', {
    // taskId: id, payload: convertedData, token,
    token, payload: { taskId: id, convertedData },
  }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(stopSubmit(form));
      dispatch(maraphonTaskFormSubmitSuccess(''));
    } else {
      dispatch(stopSubmit(form, 'error'));
      dispatch(maraphonTaskFormSubmitFailed());
    }
  });
};

const maraphonTaskClearFlashMessage = () => ({
  type: MARAPHON_TASK_CLEAR_FLASH_MESSAGE,
});

export {
  MARAPHON_TASK_PROGRESS,
  MARAPHON_TASK_SUCCESS,
  MARAPHON_TASK_FAILED,
  MARAPHON_TASK_GET_LIST_PROGRESS,
  MARAPHON_TASK_GET_LIST_SUCCESS,
  MARAPHON_TASK_GET_LIST_FAILED,
  MARAPHON_TASK_FORM_SUBMIT_PROGRESS,
  MARAPHON_TASK_FORM_SUBMIT_SUCCESS,
  MARAPHON_TASK_FORM_SUBMIT_FAILED,
  MARAPHON_TASK_GET_MATERIAL_PROGRESS,
  MARAPHON_TASK_GET_MATERIAL_SUCCESS,
  MARAPHON_TASK_GET_MATERIAL_FAILED,
  MARAPHON_TASK_CLEAR_STATE,
  MARAPHON_TASK_CLEAR_FLASH_MESSAGE,
  maraphonTaskGetListProgress,
  maraphonTaskGetListSuccess,
  maraphonTaskGetListFailed,
  maraphonTaskStartFormSubmit,
  maraphonTaskFormSubmitSuccess,
  maraphonTaskFormSubmitFailed,
  maraphonTaskGetTasklProgress,
  maraphonTaskGetTaskSuccess,
  maraphonTaskGetTaskFailed,
  maraphonTaskClearState,
  getMaraphonTasksList,
  convertSetMaraphonTaskToDB,
  createMaraphonTask,
  getMaraphonTask,
  updateMaraphonTask,
  maraphonTaskClearFlashMessage,
};
