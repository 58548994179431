import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Button, ButtonToolbar, Col, Row,
} from 'reactstrap';
import { Field } from 'redux-form';
import ArrowUpBold from 'mdi-react/ArrowUpBoldIcon';
import ArrowDownBoldIcon from 'mdi-react/ArrowDownBoldIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';
import renderField from '../../../shared/components/form/TextField';
import PopupVideoPlayer from '../../../shared/components/PopupVideoPlayer';
import PopupImage from '../../../shared/components/PopupImage';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';

class RenderVideos extends PureComponent {
  static propTypes = {
    fields: PropTypes.objectOf(PropTypes.any).isRequired,
    meta: PropTypes.objectOf(PropTypes.any).isRequired,
    reduxFormName: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired,
  };

  static moveToUp(fields, index) {
    if (index > 0) {
      fields.swap(index, index - 1);
    }
  }

  static moveToDown(fields, index) {
    if (fields.length - 1 !== index) {
      fields.swap(index, index + 1);
    }
  }

  render() {
    const {
      fields, reduxFormName, fieldName, meta: { error, submitFailed },
    } = this.props;
    const { moveToUp, moveToDown } = RenderVideos;

    return (
      <div className="form__form-group">
        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
          <Button color="primary" size="sm" onClick={() => fields.push({})}>Добавить видео</Button>
        </ButtonToolbar>
        {submitFailed && error
        && (
          <div className="form__form-group">
            <div>
              <span className="form__form-group-error">{error}</span>
            </div>
          </div>
        )}
        {fields.map((video, index) => (
          <div key={index} className="form__form-group">
            <span className="form__form-group-label">Видео # {index + 1} (шорт-код %video{index + 1}%)</span>
            <Field
              name={`${video}.title`}
              component={renderField}
              type="hidden"
            />
            <Field
              name={`${video}.wistiaId`}
              component={renderField}
              type="hidden"
            />
            <Row>
              <Col md={5} lx={5} className="form__form-group-field">
                <Field
                  name={`${video}.source`}
                  component={renderField}
                  type="text"
                  placeholder="Вставьте url видео m3u8"
                />
                <PopupVideoPlayer
                  title="Предварительный просмотр"
                  color="primary"
                  fieldIndex={index}
                  fieldName={fieldName}
                  fieldSubName="source"
                  reduxFormName={reduxFormName}
                  header
                />
              </Col>
              <Col md={5} lx={5} className="form__form-group-field">
                <Field
                  name={`${video}.alternative`}
                  component={renderField}
                  type="text"
                  placeholder="Вставьте url видео mp4"
                />
                <PopupVideoPlayer
                  title="Предварительный просмотр"
                  color="primary"
                  fieldIndex={index}
                  fieldName={fieldName}
                  fieldSubName="alternative"
                  reduxFormName={reduxFormName}
                  header
                  isMp4
                />
              </Col>
              <Col md={2} lx={2} className="form__form-group-field justify-content-end">
                <div className="form__form-group-icon">
                  <ArrowUpBold onClick={() => moveToUp(fields, index)} />
                </div>
                <div className="form__form-group-icon">
                  <ArrowDownBoldIcon onClick={() => moveToDown(fields, index)} />
                </div>
                <div className="form__form-group-icon">
                  <TrashCanOutlineIcon onClick={() => fields.remove(index)} />
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={5} lg={5} className="form__form-group-field">
                <Field
                  name={`${video}.poster`}
                  component={renderField}
                  type="text"
                  placeholder="Постер"
                />
                <PopupImage
                  title="Предварительный просмотр"
                  color="primary"
                  fieldIndex={index}
                  fieldName={fieldName}
                  fieldSubName="poster"
                  reduxFormName={reduxFormName}
                  header
                />
              </Col>
              <Col md={3} lg={2} className="form__form-group-field">
                <Field
                  name={`${video}.isLong`}
                  component={renderCheckBoxField}
                  label="Вертикальное видео"
                />
              </Col>
              <Col md={4} lg={5} className="form__form-group-field">
                <Field
                  name={`${video}.isFree`}
                  component={renderCheckBoxField}
                  label="Предпросмотр на витрине"
                />
              </Col>
            </Row>
          </div>
        ))}
      </div>
    );
  }
}

export default RenderVideos;
